// extracted by mini-css-extract-plugin
export var bmaIconsSvg = "bristolagency-module--bmaIconsSvg--zzKCM";
export var bmaIconsText = "bristolagency-module--bmaIconsText--DhGZW";
export var bristolAgencyLogo = "bristolagency-module--bristolAgencyLogo--4n-yO";
export var bristolLandscape = "bristolagency-module--bristolLandscape--GkzL1";
export var egServicesBg = "bristolagency-module--egServicesBg--9hy9L";
export var egServicesCon = "bristolagency-module--egServicesCon--qCoHl";
export var iconsCon = "bristolagency-module--iconsCon--BLJcJ";
export var logoCon = "bristolagency-module--logoCon--D9J3w";
export var mobMenuCon = "bristolagency-module--mobMenuCon--yyT8W";
export var mobMenuDesigns = "bristolagency-module--mobMenuDesigns--5KUwe";
export var mobMenuGifCon = "bristolagency-module--mobMenuGifCon--5yOLf";
export var mobMenuText = "bristolagency-module--mobMenuText--mrh-W";
export var mobMenuVideo = "bristolagency-module--mobMenuVideo--MZpQy";